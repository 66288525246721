export const MyEventsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      d="M 12.259 2.217 L 1.741 2.217 C 1.3319999999999999 2.217 1 2.549 1 2.958 L 1 12.425 C 1 12.834 1.3319999999999999 13.166 1.741 13.166 L 12.259 13.166 C 12.668 13.166 13 12.834 13 12.425 L 13 2.958 C 13 2.549 12.668 2.217 12.259 2.217 Z M 9.672 0 L 9.672 4.428 M 4.33 0 L 4.33 4.428 M 13 6.5120000000000005 L 1 6.5120000000000005"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
