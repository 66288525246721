export const MessagesIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M 13 3.7439999999999998 L 7 6.986 L 1 3.7439999999999998 M 12.259 1.334 L 1.741 1.334 C 1.3319999999999999 1.334 1 1.666 1 2.075 L 1 9.709 C 1 10.118 1.3319999999999999 10.45 1.741 10.45 L 12.259 10.45 C 12.668 10.45 13 10.118 13 9.709 L 13 2.075 C 13 1.666 12.668 1.334 12.259 1.334 Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
