export const MyCarsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M 8 15 C 11.866 15 15 11.866 15 8 C 15 4.134 11.866 1 8 1 C 4.134 1 1 4.134 1 8 C 1 11.866 4.134 15 8 15 Z M 7.998 10.17 C 9.198 10.17 10.17 9.198 10.17 7.998 C 10.17 6.7989999999999995 9.198 5.8260000000000005 7.998 5.8260000000000005 C 6.7989999999999995 5.8260000000000005 5.8260000000000005 6.7989999999999995 5.8260000000000005 7.998 C 5.8260000000000005 9.198 6.7989999999999995 10.17 7.998 10.17 Z M 6.271 8 L 1 8 M 14.999 8 L 9.729 8 M 8 14.999 L 8 9.729"
      strokeWidth="2"
      strokeMiterlimit="10"
      style={{ stroke: color }}
    />
  </svg>
)
