export const ReadingListIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M 10.697 2.023 L 10.697 7.831 M 13.6 4.928 L 7.793 4.928 M 8.177 1 L 1.67 1 C 1.492 1 1.322 1.073 1.196 1.203 C 1.071 1.333 1 1.51 1 1.694 L 1 14 L 5.998 12.424 L 11 14 L 11 9.065"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
