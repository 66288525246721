export const SignOutIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M 0 7.125 L 8.992 7.125 M 5.867 4.002 L 8.991 7.126 L 5.867 10.251 M 2.668 2.794 C 3.525 1.937 4.616 1.354 5.805 1.1179999999999999 C 6.993 0.881 8.225 1.003 9.344 1.466 C 10.463 1.9300000000000002 11.42 2.715 12.093 3.723 C 12.766 4.73 13.125 5.914 13.125 7.126 C 13.125 8.338 12.766 9.522 12.093 10.529 C 11.42 11.537 10.463 12.322 9.344 12.786 C 8.225 13.249 6.993 13.371 5.805 13.134 C 4.616 12.898 3.525 12.315 2.668 11.458"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
