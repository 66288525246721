import { render } from 'react-dom'
import UserProvider from '../../AccountV2/Providers/UserProvider'
import { SimpleSidebar } from '../../AccountV2/SimpleSidebar'
import { AppStateProvider } from '../../AccountV2/Providers/AppStateProvider'

const AccountMenu = () => (
  <UserProvider>
    <AppStateProvider>
      <SimpleSidebar />
    </AppStateProvider>
  </UserProvider>
)

function renderFunc() {
  const element =
    window.innerWidth < 992
      ? document.querySelector('#account-nav-mobile')
      : document.querySelector('#account-nav-desktop')

  if (element && App.State.userSignedIn) {
    render(<AccountMenu />, element)
  }
}

document.addEventListener('DOMContentLoaded', renderFunc)
window.addEventListener('resize', renderFunc)
