import PropTypes from 'prop-types'
import { useAppStateContext } from '../Providers/AppStateProvider'

/**
 * FormActionWrapper wraps something into form element with two hidden inputs.
 * First hidden input is for method type.
 * Second hidden input is for authenticity token.
 * This component exist to implement backend requirements.
 * @category Account
 * @component
 */
const FormActionWrapper = ({ children, method, to }) => {
  // const {
  //   state: { csrfToken },
  //   loading,
  // } = useAppStateContext()

  // if (loading) return null

  // TODO: Fix authenticity token problems
  return (
    <form className="button_to" method="post" action={to}>
      <input type="hidden" name="_method" value={method} />
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector('meta[name="csrf-token"]').content}
      />
      <button type="submit" className="w-100 border-0 bg-transparent px-0">
        {children}
      </button>
    </form>
  )
}

FormActionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  method: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default FormActionWrapper
