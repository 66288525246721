import { createContext, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

const DefaultUserState = {
  displayName: '',
  avatar: '',
  confirmed: false,
  approved: false,
  unread_messages_count: 0,
  admin: false,
}

const UserContext = createContext(DefaultUserState)

/**
 * Hook to use user context.
 * @function
 * @category Account
 * @subcategory Provider
 * @returns {{approved: boolean, displayName: string, admin: boolean, avatar: string, confirmed: boolean, unread_messages_count: number}}
 */
export const useUserContext = () => useContext(UserContext)

/**
 * UserProvider object that returns UserContext.Provider
 * @category Account
 * @subcategory Provider
 * @component
 */
function UserProvider({ children }) {
  const [user, setUser] = useState(DefaultUserState)
  const [loading, setLoading] = useState(true)

  // Get user info when component mounts.
  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    // If user info is loaded, set loading to false.
    if (user !== DefaultUserState) {
      setLoading(false)
    }
  }, [user])

  const getUserInfo = () => {
    if (App.State.user) {
      const userData = App.State.user

      setUser({
        displayName: userData.display_name,
        avatar: userData.avatar,
        confirmed: userData.confirmed,
        approved: userData.approved,
        unread_messages_count: userData.unread_message_count,
        admin: userData.admin,
      })
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserProvider
