import { createContext, useState, useEffect, useContext } from 'react'

const DefaultAppState = {}

const AppStateContext = createContext(DefaultAppState)
export const useAppStateContext = () => useContext(AppStateContext)

export function AppStateProvider({ children }) {
  const [state, setState] = useState(DefaultAppState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setState(App.State)
  }, [])

  useEffect(() => {
    if (state !== {}) {
      setLoading(false)
    }
  }, [state])

  return (
    <AppStateContext.Provider
      value={{
        state,
        loading,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateContext
