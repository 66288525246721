export const SettingsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_4116_6855)">
      <path
        d="M 0 3.5140000000000002 L 8 3.5140000000000002 M 8.001 3.4 C 8.001 4.771 9.143 5.914 10.401 5.914 C 11.658 5.914 12.915 4.771 12.915 3.4 C 12.915 2.029 11.772 1 10.515 1 C 9.258 1 8.001 2.143 8.001 3.4 Z M 12.915 3.5140000000000002 L 16 3.5140000000000002 M 3.086 11.514 C 3.086 12.886 4.229 14.029 5.6 14.029 C 6.9719999999999995 14.029 8 12.886 8 11.514 C 8 10.143 6.857 9 5.486 9 C 4.115 9 3.086 10.143 3.086 11.514 Z M 0 11.514 L 3.086 11.514 M 8 11.514 L 16 11.514"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
)
