export const MyGarageIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.952 2L10.16 5.264L14 6.32L11.504 9.392L11.696 13.424L7.952 11.984L4.304 13.424L4.496 9.392L2 6.32L5.84 5.264L7.952 2Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
