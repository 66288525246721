export const MyAuctionsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M 8.966 2.193 L 2.191 8.967 L 5.444 12.219 L 12.218 5.445 L 8.966 2.193 Z M 7.773 1 L 13.41 6.6370000000000005 M 1 7.773 L 6.6370000000000005 13.41 M 8.762 8.762 L 13.464 13.464 M 11.932 15.001 L 15.001 11.932"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
