import styled from 'styled-components'
import Menu from './components/Menu'

const StyledWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`

export const SimpleSidebar = () => (
  <StyledWrapper>
    <Menu />
  </StyledWrapper>
)
