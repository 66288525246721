import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledSpan = styled.span`
  color: ${(props) => (props.disabled ? '#B6B3B0' : '#343434')};
  font-weight: bold;
`

const StyledDiv = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  background-color: ${(props) => (props.disabled ? '#EDE9E5' : '#f9b533')};
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  line-height: ${(props) => (props.size * 2) / 3}px;
`

/**
 * Round colored badge component with changeable size.
 * Currently used in WizardForm sidebar & as message badge.
 * @category Account
 * @component
 */
export const RoundLabel = ({ text, size = 20, disabled }) => (
  <StyledDiv size={size} disabled={disabled}>
    <StyledSpan disabled={disabled}>{text}</StyledSpan>
  </StyledDiv>
)

RoundLabel.propTypes = {
  /** Badge text */
  text: PropTypes.string.isRequired,

  /** Badge size */
  size: PropTypes.number,

  /** Renders disabled version if disabled == true */
  disabled: PropTypes.bool,
}

RoundLabel.defaultProps = {
  disabled: false,
  size: 20,
}
