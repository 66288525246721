export const HistoryIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      d="M 2.854 11.587 C 3.685 12.418 4.744 12.984 5.896 13.213 C 7.049 13.443 8.244 13.325 9.33 12.875 C 10.416 12.426 11.345 11.664 11.998 10.686 C 12.651 9.709 12.999 8.56 12.999 7.385 C 12.999 6.209 12.651 5.06 11.998 4.083 C 11.345 3.105 10.416 2.344 9.33 1.8940000000000001 C 8.244 1.444 7.049 1.326 5.896 1.556 C 4.744 1.785 3.685 2.351 2.854 3.182 L 1.004 5.035 M 5.035 5.035 L 1 5.035 L 1 1"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
