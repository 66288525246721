export const AccountSettingsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M 6.886 7.366 C 8.644 7.366 10.069 5.941 10.069 4.183 C 10.069 2.425 8.644 1 6.886 1 C 5.128 1 3.703 2.425 3.703 4.183 C 3.703 5.941 5.128 7.366 6.886 7.366 Z M 12.772 13.251 C 12.772 11.69 12.152 10.193 11.048 9.089 C 9.944 7.985 8.447 7.365 6.886 7.365 C 5.325 7.365 3.8280000000000003 7.985 2.724 9.089 C 1.62 10.193 1 11.69 1 13.251"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
