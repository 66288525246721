import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormActionWrapper from './FormActionWrapper'

const StyledWrapper = styled.li`
  background-color: ${(props) => (props.isActive ? '#EDE9E5' : '#FFF')};
  width: 100%;
  padding: 1em;
  margin: 0;
  border-radius: 8px;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#EDE9E5' : '#f9f7f6')};
  }
`

const TextWrapper = styled.span`
  color: #343434;
  margin-left: 0.8em;
`

const Flex = styled.div`
  display: flex;
`

/**
 * Single menu link item used in Menu component.
 * @category Account
 * @component
 */
const MenuLinkItem = ({
  text,
  icon,
  badge,
  to,
  isActive,
  formAction = null,
}) => {
  const renderContent = () => (
    <StyledWrapper isActive={isActive}>
      <Flex>
        {icon && (
          <span style={{ width: '30px', textAlign: 'center' }}>{icon}</span>
        )}
        <TextWrapper>{text}</TextWrapper>
        {badge && <span className="ms-auto">{badge}</span>}
      </Flex>
    </StyledWrapper>
  )

  if (formAction !== null) {
    return (
      <FormActionWrapper method={formAction} to={to}>
        {renderContent()}
      </FormActionWrapper>
    )
  }

  return <a href={to}>{renderContent()}</a>
}

MenuLinkItem.propTypes = {
  /** Menu link item text */
  text: PropTypes.string.isRequired,

  /** Menu link item icon */
  icon: PropTypes.node,

  /** Menu link item badge (eg. unread messages count) */
  badge: PropTypes.node,

  /** Menu link item link */
  to: PropTypes.string.isRequired,

  /** Slightly changes color if active */
  isActive: PropTypes.bool,

  /** Form action for form element */
  formAction: PropTypes.oneOf(['delete', 'patch', 'post', 'put']),
}

MenuLinkItem.defaultProps = {
  icon: null,
  badge: null,
  isActive: false,
  formAction: null,
}

export default MenuLinkItem
