import styled from 'styled-components'
import MenuLinkItem from './MenuLinkItem'
import { MyCarsIcon } from '../Icons/MyCarsIcon'
import { MyAuctionsIcon } from '../Icons/MyAuctionsIcon'
import { MessagesIcon } from '../Icons/MessagesIcon'
import { MyEventsIcon } from '../Icons/MyEventsIcon'
import { MyGarageIcon } from '../Icons/MyGarageIcon'
import { HistoryIcon } from '../Icons/HistoryIcon'
import { ReadingListIcon } from '../Icons/ReadingListIcon'
import { AccountSettingsIcon } from '../Icons/AccountSettingsIcon'
import { SignOutIcon } from '../Icons/SignOutIcon'
import { useUserContext } from '../Providers/UserProvider'
import { RoundLabel } from './RoundLabel'
import { SettingsIcon } from '../Icons/SettingsIcon'
import { DealerSettingsIcon } from '../Icons/DealerSettingsIcon'

const StyledWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  svg {
    transform: translateY(-1px);
  }
`

/**
 * Renders full account sidebar menu with all links.
 * Uses UserContext to get user data.
 * If user is approved it shows 'Create new auction' button.
 * If user is not approved it shows private user profile.
 * If user is admin it shows admin dashboard links.
 * @category Account
 * @component
 */
const Menu = () => {
  const { user } = useUserContext()

  return (
    <StyledWrapper>
      <MenuLinkItem
        text={I18n.t('navbar.account.my_cars')}
        to="/account/cars"
        icon={
          <MyCarsIcon color={isPath('/account/cars') ? '#343434' : '#B6B3B0'} />
        }
        isActive={isPath('/account/cars')}
      />
      {user.approved && (
        <MenuLinkItem
          text={I18n.t('navbar.account.my_auctions')}
          to="/account/auctions"
          icon={
            <MyAuctionsIcon
              color={isPath('/account/auctions') ? '#343434' : '#B6B3B0'}
            />
          }
          isActive={isPath('/account/auctions')}
        />
      )}
      <MenuLinkItem
        text={I18n.t('navbar.account.messages')}
        to="/account/messages"
        icon={
          <MessagesIcon
            color={isPath('/account/messages') ? '#343434' : '#B6B3B0'}
          />
        }
        badge={
          user.unread_messages_count > 0 && (
            <RoundLabel text={user.unread_messages_count} />
          )
        }
        isActive={isPath('/account/messages')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.my_events')}
        to="/account/events"
        icon={
          <MyEventsIcon
            color={isPath('/account/events') ? '#343434' : '#B6B3B0'}
          />
        }
        isActive={isPath('/account/events')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.my_garage')}
        to="/account/listings"
        icon={
          <MyGarageIcon
            color={isPath('/account/listings') ? '#343434' : '#B6B3B0'}
          />
        }
        isActive={isPath('/account/listings')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.my_orders')}
        to="/account/orders"
        icon={
          <MyGarageIcon
            color={isPath('/account/orders') ? '#343434' : '#B6B3B0'}
          />
        }
        isActive={isPath('/account/orders')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.history')}
        to="/account/history"
        icon={
          <HistoryIcon
            color={isPath('/account/history') ? '#343434' : '#B6B3B0'}
          />
        }
        isActive={isPath('/account/history')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.reading_list')}
        to="/account/reading_list_items"
        icon={
          <ReadingListIcon
            color={
              isPath('/account/reading_list_items') ? '#343434' : '#B6B3B0'
            }
          />
        }
        isActive={isPath('/account/reading_list_items')}
      />
      <MenuLinkItem
        text={I18n.t('navbar.account.dealer_profile')}
        to="/account/settings/dealer_profile"
        icon={
          <DealerSettingsIcon
            color={
              isPath('/account/settings/dealer_profile') ? '#343434' : '#B6B3B0'
            }
          />
        }
        isActive={isPath('/account/settings/dealer_profile')}
      />
      {!user.approved && (
        <MenuLinkItem
          text={I18n.t('navbar.account.private_profile')}
          to="/account/settings/private_profile"
          icon={
            <AccountSettingsIcon
              color={
                isPath('/account/settings/private_profile')
                  ? '#343434'
                  : '#B6B3B0'
              }
            />
          }
          isActive={isPath('/account/settings/private_profile')}
        />
      )}
      <MenuLinkItem
        text={I18n.t('navbar.account.settings')}
        to="/account/settings"
        icon={
          <SettingsIcon
            color={isPath('/account/settings') ? '#343434' : '#B6B3B0'}
          />
        }
        isActive={isPath('/account/settings')}
      />
      {user.admin && (
        <>
          <hr style={{ margin: '.5em', color: '#858585' }} />
          <MenuLinkItem text="Admin V1" to="/admin/dashboard" icon="->" />
          <MenuLinkItem text="Admin V2" to="/admin/#/" icon="->" />
        </>
      )}
      <hr style={{ margin: '.5em', color: '#858585' }} />
      <MenuLinkItem
        text={I18n.t('navbar.account.sign_out')}
        to="/users/sign_out"
        icon={<SignOutIcon color="#B6B3B0" />}
        formAction="delete"
      />
    </StyledWrapper>
  )
}

export default Menu

const isPath = (path) => window.location.pathname === path
