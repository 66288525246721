export const DealerSettingsIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.4058 4.70613H2.58639C2.26038 4.70613 1.99609 4.97158 1.99609 5.29902V12.535C1.99609 12.8624 2.26038 13.1279 2.58639 13.1279H13.4058C13.7318 13.1279 13.9961 12.8624 13.9961 12.535V5.29902C13.9961 4.97158 13.7318 4.70613 13.4058 4.70613Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M5.79395 4.70452V2.45964C5.79395 2.20592 5.99897 2 6.25158 2H9.74241C9.99502 2 10.2 2.20592 10.2 2.45964V4.70452"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
